import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50')
];

export const server_loads = [0,3,4,5,7,8,10,12,11];

export const dictionary = {
		"/": [16],
		"/(authenticated)/admin": [21,[3,4]],
		"/(authenticated)/admin/templates": [~22,[3,4]],
		"/(authenticated)/admin/templates/[templateID]": [23,[3,4]],
		"/(authenticated)/admin/templates/[templateID]/[templateVersion]": [24,[3,4,5]],
		"/(authenticated)/admin/templates/[templateID]/[templateVersion]/[sectionID]": [~25,[3,4,5,6]],
		"/(authenticated)/admin/users": [26,[3,4]],
		"/(authenticated)/admin/users/[userID]": [27,[3,4,7]],
		"/(authenticated)/admin/users/[userID]/[profileID]": [28,[3,4,7,8]],
		"/(authenticated)/applications": [29,[3,9]],
		"/(authenticate-account)/challenge": [17,[2]],
		"/coming-soon": [47],
		"/(authenticate-account)/confirmation": [18,[2]],
		"/contact-us": [48],
		"/(authenticated)/grant-projects": [30,[3,10]],
		"/(authenticated)/grant-projects/[projectID]": [~32,[3,10]],
		"/(authenticated)/grant-projects/[profileName]/users": [31,[3,10]],
		"/(authenticated)/grants-in-progress": [36,[3,12]],
		"/(authenticated)/grants-in-progress/[profileName]/[proposalID]/export-grant": [40,[3,12,13]],
		"/(authenticated)/grants-in-progress/[profileName]/[proposalID]/(write-grant)/grant-details": [37,[3,12,13,14]],
		"/(authenticated)/grants-in-progress/[profileName]/[proposalID]/grant-info": [41,[3,12,13]],
		"/(authenticated)/grants-in-progress/[profileName]/[proposalID]/grant-info/key-personnel": [42,[3,12,13]],
		"/(authenticated)/grants-in-progress/[profileName]/[proposalID]/review-grant": [43,[3,12,13]],
		"/(authenticated)/grants-in-progress/[profileName]/[proposalID]/(write-grant)/write-grant": [38,[3,12,13,14]],
		"/(authenticated)/grants-in-progress/[profileName]/[proposalID]/(write-grant)/write-grant/[sectionName]": [39,[3,12,13,14]],
		"/(authenticated)/grants": [33,[3,11]],
		"/(authenticated)/grants/[grantId=grantName]": [34,[3,11]],
		"/(authenticated)/grants/[grantId=grantName]/write": [35,[3,11]],
		"/(authenticated)/help": [44,[3]],
		"/(authenticate-account)/login": [19,[2]],
		"/(authenticated)/onboarding": [45,[15]],
		"/privacy-policy": [49],
		"/(authenticated)/settings/account": [46,[3]],
		"/(authenticate-account)/signup": [20,[2]],
		"/terms": [50]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';